export const skills = [

    {
        type:"acrobatics",
        name:"Acrobacia (Des)",
        mod:'dex'
    },
    {
        type:"animal",
        name:"Lidar com Animais (Sab)",
        mod:'wis'
    },
    {
        type:"arcana",
        name:"Arcanismo (Int)",
        mod:'inte'
    },
    {
        type:"athletics",
        name:"Atletismo (For)",
        mod:'str'
    },
    {
        type:"deception",
        name:"Enganação (Car)",
        mod:'cha'
    },
    {
        type:"history",
        name:"História (Int)",
        mod:'inte'
    },
    {
        type:"insight",
        name:"Intuição (Sab)",
        mod:'wis'
    },
    {
        type:"intimidation",
        name:"Intimidação (Car)",
        mod:'cha'
    },
    {
        type:"investigation",
        name:"Investigação (Int)",
        mod:'inte'
    },
    {
        type:"medicine",
        name:"Medicina (Int)",
        mod:'inte'
    },
    {
        type:"nature",
        name:"Natureza (Int)",
        mod:'inte'
    },
    {
        type:"perception",
        name:"Percepeção (Sab)",
        mod:'wis'
    },
    {
        type:"performance",
        name:"Atuação (Car)",
        mod:'cha'
    },
    {
        type:"persuasion",
        name:"Persuasão (Car)",
        mod:'cha'
    },
    {
        type:"religion",
        name:"Religião (Int)",
        mod:'inte'
    },
    {
        type:"sleight",
        name:"Prestidigitação (Des)",
        mod:'dex'
    },
    {
        type:"stealth",
        name:"Furtividade (Des)",
        mod:'dex'
    },
    {
        type:"survival",
        name:"Sobrevivência (Sab)",
        mod:'wis'
    },
    {
        type:"safe_str",
        name:"Salvaguarda Força",
        mod:'str'
    }
    ,
    {
        type:"safe_dex",
        name:"Salvaguarda Destreza",
        mod:'dex'
    }
    ,
    {
        type:"safe_con",
        name:"Salvaguarda Constituição",
        mod:'con'
    }
    ,
    {
        type:"safe_inte",
        name:"Salvaguarda Inteligência",
        mod:'inte'
    }
    ,
    {
        type:"safe_wis",
        name:"Salvaguarda Sabedoria",
        mod:'wis'
    }
    ,
    {
        type:"safe_cha",
        name:"Salvaguarda Carisma",
        mod:'cha'
    }
    
    
]