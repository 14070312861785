import { Container } from "./styles";

export function DiceList({rollDice}){
    return(
        <Container>
            <div onClick={()=>rollDice('puredice','any',4)}>d4</div>
            <div onClick={()=>rollDice('puredice','any',6)}>d6</div>
            <div onClick={()=>rollDice('puredice','any',8)}>d8</div>
            <div onClick={()=>rollDice('puredice','any',10)}>d10</div>
            <div onClick={()=>rollDice('puredice','any',12)}>d12</div>
            <div onClick={()=>rollDice('puredice','any',20)}>d20</div>
            <div onClick={()=>rollDice('puredice','any',100)}>d100</div>
        </Container>
    )
}