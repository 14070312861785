import { Container } from "./styles";

export function RollItem({item}){

    return(
        <Container>
        <div>
            <strong>{item.type}</strong>
            <hr />
            <p>{item.name}</p>
        </div>
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', flexDirection:'column',}}>
            <h1 style={{marginTop:'1rem'}}>{item.value}</h1>
            <p>{item.details}</p>
        </div>
        
        </Container>
    )

}