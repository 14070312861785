import { useEffect, useState } from "react";
import API from "../../services/api";
import { RollItem } from "./RollItem";
import { Container, Rolls } from "./styles";

import { AiOutlineReload } from 'react-icons/ai'

export function RollBox({reloadRolls, isMobile}){

    const [ rolls, setRolls ] = useState()
    const [ campaingId, setCampaingId ] = useState('')

    async function startScreen(){

        // const pathArray = window.location.pathname.split('/');
        // http://localhost:5173//7f3f84b0-8748-418d-88cf-0a8fc6c0604e/f97308d3-4472-4d1d-9f66-d7bc27dc8f04

        // const campaingHash = pathArray[1]
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const campaingHash = urlParams.get('idc')
        setCampaingId(campaingHash)

    }

    async function getRolls(){

        const {data} = await API.get('get_rolls.php?id_campaing='+campaingId)
        setRolls(data)
    } 

    useEffect(()=>{
        startScreen()
    },[])

    useEffect(()=>{
        
        getRolls()

    },[reloadRolls])

    useEffect(()=>{
        getRolls()
    },[campaingId])

    return (
        <Container style={{display:isMobile?'none':'flex'}}>
            <Rolls>
                <div style={{display:'flex', width:'80%',alignItems:'center', justifyContent:'space-between'}}>
                    <h4>Rolagens </h4> 
                    <br /><br />
                    <AiOutlineReload onClick={getRolls} style={{cursor:'pointer'}} />
                </div>

                {
                    rolls!==undefined
                    ?rolls.map((item)=><RollItem key={item.id} item={item} />)
                    :null
                }

                
                
            </Rolls>
        </Container>
    )
}