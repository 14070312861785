import styled from "styled-components";

export const Container = styled.div`

    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;

    div{

        border: 1px solid white;
        padding: 0.3rem;
        font-size: 0.7rem;
        margin-right: .3rem;
        border-radius: .3rem;
        text-align: center;
        cursor: pointer;
        width: 2rem;

        @media only screen and (max-width: 720px)  {
            width: 2rem !important;
            min-width: 2rem !important;
        }

        &:hover{
            background: #525252;
        }
    }

`