import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 1rem;
    margin-right: 1rem;

`;

export const Rolls = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: absolute;
    top: 1rem;
    right: 1rem; */
    /* background: grey; */
    width: 12rem;
    height: 95vh;
    border-radius: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;

`;