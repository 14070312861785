import { useState } from "react"
import API from "../../services/api"

export function Actions(){

    const [ term, setTerm ] = useState('')
    const [ result, setResult ] = useState([])

    async function getActions(){
        if(term.length>=3){
            const { data } = await API.post('get_actions.php?',{term:term})
            let newArr = []
            data.map((item)=>{
                newArr.push(...item.actions.split("##").slice(1))
            })

            // const newData = data.filter((item, 
            //     index) => arr.indexOf(item) === index);

            // const match = newArr.find(element => {
            //     if (element.toLowerCase().includes(term.toLowerCase())) {
            //       return true;
            //     }
            //   });

              const match = newArr.filter(element => {
                if (element.toLowerCase().split("//")[0].includes(term.toLowerCase())) {
                  return true;
                }
              });

            //   const other = match.filter((item, 
            //     index) => match.indexOf(item.toLowerCase().split("//")[0]) === index.toLowerCase().split("//")[0]);

            let uniqueChars = [...new Set(match)];
            setResult(uniqueChars)
        }else{
            window.alert("Digite pelo menos 3 letras antes de buscar")
        }
        
    }


    return(
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                width:'100%',
                margin:'1rem'
            }}>
            <div>
                <input style={{
                    background:'transparent',
                    border:'1px solid white',
                    height:'2rem',
                    borderRadius:'1rem',
                    color:'white',
                    marginRight:'1rem',
                    paddingLeft:'1rem'
                }} type="text" 
                onChange={(e)=>setTerm(e.target.value)} 
                onKeyDown={(e)=>e.keyCode===13?getActions():null}
                />
                <button
                    style={{
                        background:'transparent',
                        border:'1px solid white',
                        height:'2rem',
                        borderRadius:'1rem',
                        color:'white',
                        cursor:'pointer'
                    }}
                 onClick={getActions}>Buscar</button>
            </div>
            <div style={{marginTop:'1rem'}}>
                {
                    result.length > 0
                    ?result.map((item)=>{
                        return(
                            <div>
                                <h2>{item.split("//")[0]}</h2>
                                <pre>##{item}</pre>
                                <hr />
                            </div>

                        )
                    })
                    :null
                }
            </div>
        </div>
    )
}