import { useEffect, useState } from "react"
import { ActionItem, MoreButton } from "./styles";
import { BsFillArrowRightSquareFill } from 'react-icons/bs'
import { AiFillInfoCircle } from 'react-icons/ai'
import { Actions } from "./styles";
import API from "../../services/api";
import { DiceListOneShot } from "../DiceListOneShot";
 

export function OneShot(){

    const [ url, setUrl ] = useState('')
    const [ showAction, setShowActions ] = useState(false)
    const [ actions, setActions ] = useState()
    const [ character, setCharacter ] = useState('')
    const [ isMobile, setIsMobile ] = useState(true)
    const [ campaingId, setCampaingId ] = useState()

    async function getCharacter(){

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let room = urlParams.get('room')
        let player = urlParams.get('player')
        let characterName = urlParams.get('character')
        let finalUrl = 'https://meet.jit.si/'+room+'#userInfo.displayName="'+characterName+" - "+player+'"&config.prejoinConfig.enabled=false&config.startWithAudioMuted=true&config.startWithVideoMuted=true'
        setUrl(finalUrl)
        setCharacter(characterName+" - "+player)


        
    }


    async function rollDice(type, label, value, pro=0, dice=20){
        // let details = ''

        let finalValue = 0

        finalValue = Math.floor( Math.random() * parseInt(value) ) +1;
        window.alert("d"+value+" : "+finalValue)

        return;
        


    //     const finalLabel = label.replace(' ','%20')
    //     let finalValue = 0

    //     if(type==='puredice'){

    //         finalValue = Math.floor( Math.random() * parseInt(value) ) +1;

    //         if(character?.id!=='admin'){
    //             await API.get('add_roll.php?id_campaing='+campaingId+'&id_character='+character?.id+'&type=d'+value+'&value='+finalValue)
    //             if(isMobile){
    //                 window.alert("d"+value+" : "+finalValue)
    //             }
    //         }else{
    //             window.alert("d"+value+" : "+finalValue)
    //         }
            
    //         return

    //     }

        

    //     if(type==='test'){
    //         const diceValue = Math.floor( Math.random() * 20 ) +1
    //          finalValue = parseInt(setModifier(value)) + diceValue;
    //          if(parseInt(setModifier(value))>=0){
    //             details = diceValue + " + "+parseInt(setModifier(value))
    //          }else{
    //             details = diceValue + " - "+(parseInt(setModifier(value))*-1)
    //          }
             
    //         if(pro===1){
    //             finalValue += Math.round(parseInt(character?.proeficience)/2)
    //             details += " + "+Math.round(parseInt(character?.proeficience)/2)
    //         }

    //         if(pro===2){
    //             finalValue += parseInt(character?.proeficience)
    //             details += " + "+parseInt(character?.proeficience)
    //         }

    //         if(pro===3){
    //             finalValue += parseInt(character?.proeficience)*2
    //             details += " + "+parseInt(character?.proeficience)*2
    //         }
            
            
    //     }else if(type==='hit'){
    //         const diceValue = Math.floor( Math.random() * 20 ) +1
    //         finalValue = parseInt(value) + diceValue;       
    //         if(parseInt(value)>=0){
    //             details = diceValue + " + "+parseInt(value) 
    //         }else{
    //             details = diceValue +" - "+(parseInt(value)*-1) 
    //         }

            
           
    //    }else if(type==='hitM'){
    //         finalValue = parseInt(value) + Math.floor( Math.random() * 20 ) +1;           
           
    //    }else{
            
    //         if(!value.includes('d')){
    //             finalValue = parseInt(value)

    //         }else{

    //             const numberOfDices = value.split('d')[0]
    //             const typeOfDice = value.split('d')[1].split('+')[0]
    //             let mod = value.split('d')[1].split('+')[1]
    //             if(mod===undefined){
    //                 mod = parseInt(value.split('d')[1].split('-')[1])*-1
    //             }

                
                

    //             if(numberOfDices>1){

    //                 for(let i=0; i<numberOfDices;i++){
    //                     const diceValue = Math.floor( Math.random() * parseInt(typeOfDice) ) +1;
    //                     finalValue =  finalValue + diceValue
    //                     if(details===''){
    //                         details += diceValue
    //                     }else{
    //                         details += " + "+diceValue
    //                     }
    //                 }

    //                 if(mod!==undefined){
    //                     finalValue =  finalValue + parseInt(mod)
    //                     if(parseInt(mod)>=0){
    //                         details += " + "+parseInt(mod)
    //                     }else{
    //                         details += " - "+(parseInt(mod)*-1)
    //                     }
                        
    //                 }
                    

    //             }else{
    //                 finalValue =  Math.floor( Math.random() * typeOfDice ) +1;
    //                 details += finalValue
    //                 if(mod!==undefined){
                        
    //                     finalValue =  finalValue + parseInt(mod)
                        
    //                     if(parseInt(mod)>=0){
    //                         details += " + "+parseInt(mod)
    //                     }else{
    //                         details += " - "+parseInt(mod)
    //                     }
    //                 }
    //             }

    //         }
            
            
    //     }

    //     if(type !=='hitM' && type !== 'effectM'){
    //         await API.get('add_roll.php?id_campaing='+campaingId+'&id_character='+character?.id+'&type='+finalLabel+'&value='+finalValue+'&details='+details.toString())
    //         if(isMobile){
    //             window.alert(label+" : "+finalValue+"("+details.toString()+")")
    //         }
    //     }else{
    //         window.alert(label+" : "+finalValue)
    //     }

        

    }

    useEffect(()=>{

        getCharacter()

    },[])

    // useEffect(()=>{
    //     getActions()
    // },[character])



    return(
        <>
        <iframe src={url} 
                allow='camera; microphone'
                is="x-frame-bypass"
                style={{margin:0, padding:0, minHeight:'100vh', width:'100%'}}
                frameborder="0">
        </iframe>
        {
            showAction
            ?<Actions>
                <p
                             style={{fontSize:'.7rem', textAlign:"right"}}
                                onClick={()=>setShowActions(false)}
                            >Fechar</p>
                            <h5>{character}</h5>
                            <DiceListOneShot rollDice={rollDice} />
                            <br />
                {
                    // actions.map((item)=>{
                    //     return(
                    //         <>
                             
                    //             <ActionItem>
                    //                 <strong style={{lineHeight:'1.7rem'}}>{item[0]}
                    //                     {   
                    //                         /^\*/.test(item[5])
                    //                         ?null
                    //                         :<>&nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[5]} /></>
                    //                     }
                    //                 </strong>
                    //                 <p>
                    //                     {
                    //                         /^\*/.test(item[1])
                    //                         ?null
                    //                         :
                    //                         <>
                    //                             HIT <span onClick={()=>rollDice('hit',item[0],item[1])} className="pill">{item[1]}</span> 
                    //                             &nbsp;
                    //                         </>
                                            
                    //                     }

                    //                     {
                    //                         (/^\*/.test(item[2]) && /^\*/.test(item[3]))
                    //                         ?null
                    //                         :'EF'
                    //                     }

                    //                     {   
                    //                         /^\*/.test(item[2])
                    //                         ?null
                    //                         :<span 
                    //                         onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[2] ,item[2])}
                    //                         className="pill">{item[2]}</span>
                    //                     }

                    //                     {   
                    //                         /^\*/.test(item[3])
                    //                         ?null
                    //                         :<span 
                    //                         onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[3] ,item[3])}
                    //                         className="pill">{item[3]}</span>
                    //                     }

                    //                     {   
                    //                         /^\*/.test(item[4])
                    //                         ?null
                    //                         :item[4]
                    //                     }

                                        
                    //                     </p>
                                        
                    //                     {/* EF:  {item.length>5?<span className="pill" onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[3],item[3])}>{item[3]}</span>:null}  */}
                    //                     {/* &nbsp;{item[item.length-2]}  */}
                    //                     {/* &nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[item.length-1]} /></p> */}
                    //             </ActionItem>
                    //             {/* <FaEdit 
                    //                 className="toTheRight" 
                    //                 style={{color:"#f5f5f5", cursor:"pointer"}} 
                    //                 onClick={()=>setEditingActions(true)}
                    //             /> */}
                    //         </>
                    //     )
                    // })
                }
               
            </Actions>
            :<MoreButton>
                <BsFillArrowRightSquareFill
                onClick={()=>setShowActions(true)}
                />
            </MoreButton>
        }
        </>
        
    )
}