import { useState } from "react";
import { Container, Dices, Header, Result } from "./styles";

export function DiceListOneShot(){

    const [ finalValue, setFinalValue ] = useState('')
    const [ lastDice, setLastDice ] = useState('')
    const [ diceNumber, setDiceNumber ] = useState(1)

    function rollDice(dice){

        if(diceNumber>1){

            let tempValue = 0
            let details = "( d"+dice+": "

            for(let i=0; i<diceNumber;i++){
                const diceValue = Math.floor( Math.random() * parseInt(dice) ) +1;
                tempValue =  tempValue + diceValue
                if(i===diceNumber-1){
                    details += diceValue+" "
                }else{
                    details += diceValue+", "
                }
            }
            details += ")"

            setFinalValue(tempValue)
            setLastDice(details)
        }else{

            setFinalValue(Math.floor( Math.random() * parseInt(dice) ) +1)
            setLastDice("( d"+dice+" )")

        }

        
    }
    return(
        <Container>
            <Header>
                <p>Quantidade</p>
                <input  type="number" value={diceNumber} onChange={(e)=>setDiceNumber(e.target.value)} />
            </Header>
            <Dices>
                <div onClick={()=>rollDice(4)}>d4</div>
                <div onClick={()=>rollDice(6)}>d6</div>
                <div onClick={()=>rollDice(8)}>d8</div>
                <div onClick={()=>rollDice(10)}>d10</div>
                <div onClick={()=>rollDice(12)}>d12</div>
                <div onClick={()=>rollDice(20)}>d20</div>
                <div onClick={()=>rollDice(100)}>d100</div>
            </Dices>

            <Result>
                <h1>{finalValue}</h1>
                <p>{lastDice}</p>
            </Result>
                
            
        </Container>
    )
}