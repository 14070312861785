import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

function App() {

  return (
    // <BrowserRouter basename='/haodungeon'>
      <BrowserRouter>
      <Routes />
    </BrowserRouter>
    
  )
}

export default App
