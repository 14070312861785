import styled from "styled-components";

export const MoreButton = styled.div`

    width: 1rem;
    height: 1rem;
    opacity: .5;
    position: fixed;
    left: 1rem;
    top: 50vh;
    cursor: pointer;
`;


export const Actions = styled.div`

    width: 15rem;
    height: 20rem;
    opacity: .9;
    position: fixed;
    left: 1rem;
    top: 10rem;
    cursor: pointer;
    overflow-y: scroll;
    background: grey;
    padding: 1rem;
    border-radius: 1rem;
`;

export const ActionItem = styled.div`
    margin-bottom: 1rem;
    font-size: .8rem;

    strong{
        line-height: 1.3rem;
    }

    .pill{
        border: 1px solid #f5f5f5;
        padding: .3rem;
        border-radius: 1rem;
        cursor: pointer;
        &:hover{
            background: #313131;
        }
    }

`;
