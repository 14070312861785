import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Actions } from '../components/Actions';

import { MainPage } from '../components/MainPage';

export default function Routes() {
    return (
        <Switch>
            <Route path="*" component={MainPage} />
            {/* <Route path="/actions" component={Actions} /> */}
        </Switch>
    );
}
