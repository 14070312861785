import styled from "styled-components";


export const Container = styled.div`

    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;
`;

export const Header = styled.div`

    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .7rem;

    input{
        width: 3rem;
        background: transparent;
        color: #FFF;
        margin-left: 1rem;
        padding-left: .5rem;
        text-align: center;
        border: none;
    }

`;

export const Result = styled.div`

    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    h1{
        font-size: 6rem;
    }
`;


export const Dices = styled.div`

    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;

    div{

        border: 1px solid white;
        padding: 0.3rem;
        font-size: 0.7rem;
        margin-right: .3rem;
        border-radius: .3rem;
        text-align: center;
        cursor: pointer;
        width: 2rem;

        @media only screen and (max-width: 720px)  {
            width: 2rem !important;
            min-width: 2rem !important;
        }

        &:hover{
            background: #525252;
        }
    }

`