import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    width: 90%;
    border: 1px solid white;
    border-radius: 1rem;
    margin: .2rem 0;
    padding: .5rem;

    div{
        width: 100%;
        height: 100%;
    }

    strong{
        margin: 0;
        font-size: .7rem;
    }
    p{
        margin: 0;
        font-size: .7rem;
    }
    h2,h3{
        margin: 0;
        text-align: center;
    }

`;