import React from 'react';
import Modal from 'react-modal';

import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from "react";
import API from "../../services/api";
import { RollBox } from "../RollBox";
import { ActionItem, AttBox, CharBox, Container, Content, Modifier, Notes, SelectCharacter } from "./styles";
import { FaRegCircle, FaRegDotCircle, FaRegCheckCircle } from 'react-icons/fa'
import { FaEdit, FaDiceD20 } from 'react-icons/fa'
import { BsCircle, BsCircleHalf, BsCircleFill, BsFileEarmarkMusicFill, BsInfoCircleFill, BsTrashFill } from 'react-icons/bs'
import { AiFillInfoCircle, AiFillDelete, AiFillVideoCamera } from 'react-icons/ai'
import { RxMagnifyingGlass } from 'react-icons/rx'
import { BiInfoCircle, BiQuestionMark, BiWorld } from 'react-icons/bi'
import { CgMoreO } from 'react-icons/cg'
import { IoIosArrowDropupCircle } from 'react-icons/io'

import Logo from '../../assets/logohd.png'



import { MdOutlineAddCircleOutline } from 'react-icons/md'

import { skills } from './skill'
import { DiceList } from '../DiceList';
import { Actions } from '../Actions';
import { TableTop } from '../TableTop';
import { OneShot } from '../OneShot';

export function MainPage(){

    const [ character, setCharacter ] = useState()
    const [ campaingId, setCampaingId ] = useState('')
    const [ selectedCampaing, setSelectedCampaing] = useState()
    const [ campaings, setCampaings ] = useState()
    const [ characterId, setCharacterId ] = useState('')
    const [ reloadRolls, setReloadRolls ] = useState(false)
    const [ panel, setPanel ] = useState('actions')
    const [ editingNote, setEditingNote ] = useState(false)
    const [ editingMonster, setEditingMonster ] = useState(0)
    const [ editingActions, setEditingActions ] = useState(false)
    const [ editingInventory, setEditingInventory ] = useState(false)
    const [ editingNoteDM, setEditingNoteDM ] = useState(false)
    const [ actions, setActions ] = useState()
    const [ screen, setScreen ] = useState('none')
    const [ characters, setCharacters ] = useState()
    const [ theDMIsOn, setTheDMIsOn ] = useState()
    const [ monsters, setMonsters ] = useState()
    const [ sounds, setSounds ] = useState()
    const [ dataMonster, setDataMonster ] = useState('')
    const [ letsBora, setLetsBora ] = useState(0)
    const [ typedId, setTypedId] = useState('')
    const [ isMobile, setIsMobile ] = useState(false)
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [ panelDM, setPanelDM ] = useState('notes')
    const [ filterSound, setFilterSound ] = useState('')
    const [ vTTLink, setVTTLink ] = useState('')

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function deleteCampaing(){
        const confirmation = window.prompt("Deseja realmente EXCLUIR a CAMPANHA? Se sim digite 'excluir campanha'")
        if(confirmation ==='excluir campanha'){

            updateCampaing('active',0)
            window.location.href="https://haodungeon.haode.com.br?idc=admin"

        }
    }

    const customStyles = {
    content: {
        // top: '50%',
        // left: '50%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)',
        background:'#242424'
    },
    };

    useEffect(()=>{

        if(letsBora>2){
            window.location.href="https://haodungeon.haode.com.br?idc=admin"
        }
    },[letsBora])

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;

        if(innerWidth<720){
            setIsMobile(true)
        }
      }

      function oneShot(){
        const room = window.prompt("Digite o NOME DA SALA")
        const player = window.prompt("Digite o nome do JOGADOR")
        const character = window.prompt("Digite o nome do PERSONAGEM")

        window.location.href='https://haodungeon.haode.com.br?idc=oneshot&room='+room+'&player='+player+'&character='+character
            
      }

      function alterAvatar(){

        const confirm = window.confirm("Deseja alterar a imagem?")

        if(confirm){
            const url = window.prompt("Digite a URL da imagem")
        
            setCharacter({...character, avatar_url:url})
            saveOnDb('avatar_url',url)

        }
        
      }

    async function editPlaylist(){
        const url = window.prompt("Qual o url da playlist?")
        setSelectedCampaing({...selectedCampaing, playlist:url})
        updateCampaing('playlist',url)
    }

    async function addSound(){
        const name = window.prompt("Qual o NOME do som?")
        const url = window.prompt("Qual a URL do som?")
        const loop = window.prompt("O som deverá ser reproduzido em loop? \n(Digite s ou n)")
        let inloop = 0;
        if(loop==='s'){
            inloop = 1;
        }

        await API.post('add_sound.php?',{name:name, url:url, inloop:inloop})

        setTimeout(()=>{
            getSounds()
          }, 1000)

        
    }

    async function deleteSound(id){
        const confirm = window.confirm("Confirma a exclusão do som?")
        if(confirm){
            await API.post('delete_sound.php?',{id:id})
            getSounds()
        }
        
        
    }

    async function deleteForReal(id){


        const confirm = window.confirm("Deseja excluir PERMANENTEMENTE o personagem?")

        if(confirm){
            saveOnDb('active',0,id)
        }

        getCharacters()


    }

    async function removeFromList(id){

        const confirm = window.confirm("Deseja remover o personagem da lista? (Para excluir definitivamente clique no ícone com o botão direito)")

        if(!confirm){
            return
        }

        

        const tempList = characters.filter(item=>item.id!==id)

        setCharacters(tempList)

    }

    async function getActions(){
        
        if(character?.actions===undefined){
            return
        }

        const data = character?.actions
        const arrData = data.split("##")
        let newArr = []

        arrData.map((item)=>{
            if(item.length>0){
                newArr.push(item.split("//"))
            }
            
        })


        setActions(newArr)
    }

    async function getMonsters(){
        
        
        const {data} = await API.get('get_monsters.php')
        setMonsters(data)
        
    }

    async function getSounds(){
        
        
        const {data} = await API.get('get_sounds.php')
        setSounds(data)
        
    }


    async function setSkill(skill){

        if(parseInt(character?.[skill])===0){
            saveOnDb(skill,1)
        }else if(parseInt(character?.[skill])===1){
            saveOnDb(skill,2)
        }else if(parseInt(character?.[skill])===2){
            saveOnDb(skill,3)
        }else{
            saveOnDb(skill,0)
        }
    }

    async function alterInspiration(){

        if(parseInt(character?.inspiration)===0){
            saveOnDb('inspiration',1)
        }else{
            saveOnDb('inspiration',0)
        }

    }

    async function rollDice(type, label, value, pro=0, dice=20){
        let details = ''
        


        const finalLabel = label.replace(' ','%20')
        let finalValue = 0

        if(type==='puredice'){

            finalValue = Math.floor( Math.random() * parseInt(value) ) +1;

            if(characterId!=='admin'){
                await API.get('add_roll.php?id_campaing='+campaingId+'&id_character='+characterId+'&type=d'+value+'&value='+finalValue)
                setReloadRolls(!reloadRolls)
                if(isMobile){
                    window.alert("d"+value+" : "+finalValue)
                }
            }else{
                window.alert("d"+value+" : "+finalValue)
            }
            
            return

        }

        

        if(type==='test'){
            const diceValue = Math.floor( Math.random() * 20 ) +1
             finalValue = parseInt(setModifier(value)) + diceValue;
             if(parseInt(setModifier(value))>=0){
                details = diceValue + " + "+parseInt(setModifier(value))
             }else{
                details = diceValue + " - "+(parseInt(setModifier(value))*-1)
             }
             
            if(pro===1){
                finalValue += Math.round(parseInt(character?.proeficience)/2)
                details += " + "+Math.round(parseInt(character?.proeficience)/2)
            }

            if(pro===2){
                finalValue += parseInt(character?.proeficience)
                details += " + "+parseInt(character?.proeficience)
            }

            if(pro===3){
                finalValue += parseInt(character?.proeficience)*2
                details += " + "+parseInt(character?.proeficience)*2
            }
            
            
        }else if(type==='hit'){
            const diceValue = Math.floor( Math.random() * 20 ) +1
            finalValue = parseInt(value) + diceValue;       
            if(parseInt(value)>=0){
                details = diceValue + " + "+parseInt(value) 
            }else{
                details = diceValue +" - "+(parseInt(value)*-1) 
            }

            
           
       }else if(type==='hitM'){
            finalValue = parseInt(value) + Math.floor( Math.random() * 20 ) +1;           
           
       }else{
            
            if(!value.includes('d')){
                finalValue = parseInt(value)

            }else{

                const numberOfDices = value.split('d')[0]
                const typeOfDice = value.split('d')[1].split('+')[0]
                let mod = value.split('d')[1].split('+')[1]
                if(mod===undefined){
                    mod = parseInt(value.split('d')[1].split('-')[1])*-1
                }

                
                

                if(numberOfDices>1){

                    for(let i=0; i<numberOfDices;i++){
                        const diceValue = Math.floor( Math.random() * parseInt(typeOfDice) ) +1;
                        finalValue =  finalValue + diceValue
                        if(details===''){
                            details += diceValue
                        }else{
                            details += " + "+diceValue
                        }
                    }

                    if(mod!==undefined){
                        finalValue =  finalValue + parseInt(mod)
                        if(parseInt(mod)>=0){
                            details += " + "+parseInt(mod)
                        }else{
                            details += " - "+(parseInt(mod)*-1)
                        }
                        
                    }
                    

                }else{
                    finalValue =  Math.floor( Math.random() * typeOfDice ) +1;
                    details += finalValue
                    if(mod!==undefined){
                        
                        finalValue =  finalValue + parseInt(mod)
                        
                        if(parseInt(mod)>=0){
                            details += " + "+parseInt(mod)
                        }else{
                            details += " - "+parseInt(mod)
                        }
                    }
                }

            }
            
            
        }

        if(type !=='hitM' && type !== 'effectM'){
            await API.get('add_roll.php?id_campaing='+campaingId+'&id_character='+characterId+'&type='+finalLabel+'&value='+finalValue+'&details='+details.toString())
            setReloadRolls(!reloadRolls)
            if(isMobile){
                window.alert(label+" : "+finalValue+"("+details.toString()+")")
            }
        }else{
            window.alert(label+" : "+finalValue)
        }

        

    }

    function setModifier(value){
        switch(parseInt(value)) {
              case 1:
                return '-5'
                break;
              case 2:
                return '-4'
                break;
              case 3:
                return '-4'
                break;
              case 4:
                return '-3'
                break;
              case 5:
                return '-3'
                break;
              case 6:
                return '-2'
                break;
              case 7:
                return '-2'
                break;
              case 8:
                return '-1'
                break;
              case 9:
                return '-1'
                break;
              case 10:
                return '+0'
                break;
              case 11:
                return '+0'
                break;
              case 12:
                return '+1'
                break;
              case 13:
                return '+1'
                break;
              case 14:
                return '+2'
                break;
              case 15:
                return '+2'
                break;
              case 16:
                return '+3'
                break;
              case 17:
                return '+3'
                break;
              case 18:
                return '+4'
                break;
              case 19:
                return '+4'
                break;
              case 20:
                return '+5'
                break;
              case 21:
                return '+5'
                break;
              case 22:
                return '+6'
                break;
              case 23:
                return '+6'
                break;
              case 24:
                return '+7'
                break;
              case 25:
                return '+7'
                break;
              case 26:
                return '+8'
                break;
              case 27:
                return '+8'
                break;
              case 28:
                return '+9'
                break;
              case 29:
                return '+9'
                break;
              case 30:
                return '+10'
                break;
              default:
                return "-"
            } 
    }

    async function startScreen(){

        // const pathArray = window.location.pathname.split('/');
        // http://localhost:5173/7f3f84b0-8748-418d-88cf-0a8fc6c0604e/f97308d3-4472-4d1d-9f66-d7bc27dc8f04

        // const campaingHash = pathArray[1]
        // const charHash = pathArray[2]
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let campaingHash = urlParams.get('idc')

        if(urlParams.get('idc')==='oneshot'){
            setScreen('oneshot')
            return false;
        }
        let charHash = urlParams.get('idch')

        if(charHash===null){
            charHash=''
        }

        if(campaingHash===null){
            campaingHash=''
        }
     

        setTheDMIsOn(window.localStorage.getItem('admin')) 

        if(campaingHash==='admin'){
            setScreen('select')
            return
        }

        if(campaingHash==='actions'){
            setScreen('actions')
            return
        }

        if(campaingHash==='tabletop'){
            setScreen('tabletop')
            return
        }


            if(campaingHash!=='' && campaingHash!=='admin'){
                setCampaingId(campaingHash)
            }

            if(charHash!==''){
                setCharacterId(charHash)
            }

            if(campaingHash!=='' && charHash!=='' && charHash!=='admin'){
                setScreen('character')
            }

            if(campaingHash!=='' && charHash===''){
                setScreen('campaing')
            }

            if(campaingHash!=='' && charHash==='admin'){
                setScreen('admin')
            }
            
            setTheDMIsOn(window.localStorage.getItem('admin'))      
            
            return true
        

    }

    async function saveOnDb(field,value,charId=''){

        if(charId === ''){
            await API.get('update_info.php?id_character='+characterId+'&field='+field+'&value='+value)
        }else{
            await API.get('update_info.php?id_character='+charId+'&field='+field+'&value='+value)
        }

        
        getCharacter()

    }

    async function saveNotes(notes){

        setEditingNote(false)

        await API.post('update_notes.php?',{notes:notes, id_character:character.id})
        getCharacter()
        
        
    }

    async function saveOtherSkills(value){

        await API.post('update_skills.php?',{skills:value, id_character:character.id})
        getCharacter()
        
    }

    async function updateCampaing(field, value){

        setEditingNoteDM(false)

        await API.post('update_campaing.php?',{id:selectedCampaing.id, field:field, value:value})
                

        getCampaings()

    }

    async function saveMonster(data){

        setEditingMonster(0)

        if(data===''){
            setEditingMonster(0)
            return
        }

        if(editingMonster===-1){
            await API.post('add_monster.php?',{data:data})
        }

        if(editingMonster>0){
            
            await API.post('update_monsters.php',{id:editingMonster, data:data})
        }
        
        getMonsters()
        
        setDataMonster('')
        
    }

    async function saveActions(actions){

        setEditingActions(false)

        await API.post('update_actions.php?',{actions:actions, id_character:character.id})
        getCharacter()
        
        
    }

    async function saveInventory(inventory){

        setEditingInventory(false)

        await API.post('update_inventory.php?',{inventory:inventory, id_character:character.id})
        getCharacter()
        
        
    }


    async function getCharacter(){

        const {data} = await API.get('get_character.php?id_character='+characterId)
        setCharacter(data[0])
        
    }   

    async function getCharacters(){

        const {data} = await API.get('get_characters.php?id_campaing='+campaingId)
        setCharacters(data)
        
    }  

    async function getCampaings(){

        const {data} = await API.get('get_campaings.php')
        setCampaings(data)
        
    }  

    async function getSelectedCampaing(){
        if(campaings !==undefined){
            const selected = campaings.filter(item=>item.id===campaingId)
            if(selected!==undefined){
                setSelectedCampaing(selected[0])
                setVTTLink(selected[0].vttlink.split("/")[4])
            }
        }
        
    }

    async function addMonster(item){

        if(item.data){

            const tempString = `FOR ${item.data.split("##")[2].split("//")[4]} | DES ${item.data.split("##")[2].split("//")[4]} | CON ${item.data.split("##")[2].split("//")[4]} | INT ${item.data.split("##")[2].split("//")[4]} | SAB ${item.data.split("##")[2].split("//")[4]} | CAR ${item.data.split("##")[2].split("//")[4]}
            \n`
            
            item.name = item.data.split("##")[1]
            item.hp = item.data.split("##")[2].split("//")[0]
            item.ca = item.data.split("##")[2].split("//")[1]
            item.pp = item.data.split("##")[2].split("//")[2]
            item.move = item.data.split("##")[2].split("//")[3]
            item.initiative = item.data.split("##")[2].split("//")[4]
            item.info = tempString+item.data.split("##")[item.data.split("##").length-1]
            item.monsterActions = []

            for(let i=3; i<item.data.split("##").length-1;i++){
                item.monsterActions.push(item.data.split("##")[i].split('//'))
            }
        }

        setCharacters([...characters,item])
    }

    async function initOrder(){

        const tempList = characters.map(item=>{

            {
                return{
                    ...item,
                    combbatIni: parseInt(item.initiative) + Math.floor( Math.random() * 20 ) +1
                }
            }

        })


        tempList.sort((a, b) => a.combbatIni < b.combbatIni ? 1 : -1);

        setCharacters(tempList)

    }


    async function reorderList(){

        const tempList = [...characters]

        tempList.sort((a, b) => a.combbatIni < b.combbatIni ? 1 : -1)
        
        setCharacters(tempList)
    }

    async function alterInitiative(id, value){

        const tempList = characters.map(item=>{

            {
                if(item.id===id){
                    return{
                        ...item,
                        combbatIni: value
                    }
                }else{
                    return item
                }
                
            }

        })

        setCharacters(tempList)

    }

    async function addCharacter(){
        const confirmation = window.confirm("Deseja criar um personagem novo?")
        if(confirmation){
            const name = window.prompt("Nome do personagem")
            const id = uuidv4()

            await API.post('add_character.php',{id:id, name:name, id_campaing:campaingId})
            
        }
        getCharacters()
    }

    async function addCampaing(){
        const confirmation = window.confirm("Deseja criar uma campanha nova?")
        if(confirmation){
            const name = window.prompt("Nome da campanha")
            const id = uuidv4()

            await API.post('add_campaing.php',{id:id, name:name})
            
        }
        getCampaings()
    }
 

    useEffect(()=>{
        getWindowSize()
        const hasCampaing = startScreen()
        console.log(hasCampaing)
        if (hasCampaing){
            getActions()
            getMonsters()
            getSounds()
            getCampaings()
        }
        

    },[])

    useEffect(()=>{
        if(theDMIsOn==='dnd@2022'){
            window.localStorage.setItem('admin','dnd@2022')
        }
    },[theDMIsOn])

    useEffect(()=>{
        if(screen==='campaing' || screen==='admin'){
            getCharacters()
        }
    },[screen])

    useEffect(()=>{
        getCharacter()
    },[characterId])


    useEffect(()=>{
        getActions()
    },[character])

    useEffect(()=>{
        getSelectedCampaing()
    },[campaings])

    useEffect(()=>{
        if(filterSound.length>2){

            const tempArr = [...sounds]

            // tempArr.filter(item=>item.name.toLowerCase().includes(filterSound.toLowerCase()))

            const matches = tempArr.filter(element => {
                if (element.name.toLowerCase().includes(filterSound.toLowerCase())) {
                  return true;
                }
              });


            setSounds(matches)

        }

        if(filterSound===''){
            getSounds()
        }
    },[filterSound])


    return(
        <Container>
            {
                screen === 'oneshot'
                ?<OneShot />
                :screen === 'tabletop'
                ?<TableTop />
                :screen === 'actions'
                ?<Actions />
                :screen === 'admin'
                    ?theDMIsOn !== 'dnd@2022'
                    ?<SelectCharacter style={{marginTop:'5rem'}}>
                        <h2 style={{marginTop:'2rem'}}>Bem-vindo, Mestre!</h2>
                        <h5>Digite a senha</h5>
                        <input onChange={(e)=>setTheDMIsOn(e.target.value)} style={{background:'#313131', border:'none', borderRadius:'1rem', textAlign:'center', color:'white'}} type="password" />
                    </SelectCharacter>
                    :<div style={{width:'100%', display:'flex',margin:'8px'}}>
                        <div style={{width:'100%'}}>
                            {/* <h2>{selectedCampaing?selectedCampaing.name:null}</h2> */}
                            <span>
                            <input type="text" className="bigInfo" value={selectedCampaing?selectedCampaing.name:null} onChange={(e)=>setSelectedCampaing({...selectedCampaing, name:e.target.value})} onBlur={(e)=>updateCampaing('name',e.target.value)}/>
                            <BsTrashFill style={{cursor:'pointer', width:'.7rem'}} onClick={deleteCampaing} />
                            </span>
                            <p
                                style={{
                                    marginTop:'.5rem',
                                    marginBottom:'.5rem',
                                    fontSize:".7rem"
                                }}
                            >{selectedCampaing?selectedCampaing.id:null}</p>
                            
                            <DiceList rollDice={rollDice} />
                            <input type="text" placeholder='Link do OwlBear' className='vttlink' value={selectedCampaing?.vttlink!==null?selectedCampaing?.vttlink:null} onChange={(e)=>setSelectedCampaing({...selectedCampaing, vttlink:e.target.value})} onBlur={(e)=>updateCampaing('vttlink',e.target.value)}/>

                            <h5>
                                <span style={{cursor:'pointer'}} onClick={()=>initOrder()}>Iniciativa</span> | 
                                <span style={{cursor:'pointer'}} onClick={()=>getCharacters()}> Finalizar encontro</span> |
                                <span style={{cursor:'pointer'}} onClick={()=>addCharacter()}> Add personagem</span> | 
                                <a style={{color:'white', textDecoration:'none'}} target={'_blank'} href={'https://haodungeon.haode.com.br?idc=actions'}> <RxMagnifyingGlass /> &nbsp;</a> 
                                {/* <a style={{color:'white', textDecoration:'none'}} target={'_blank'} href={'https://haodungeon.haode.com.br?idc=soundtable'}> <BsFileEarmarkMusicFill /> &nbsp;</a>  */}
                                
                                &nbsp;<CgMoreO style={{cursor:'pointer'}} onClick={openModal} />
                                </h5>
                            {
                                characters !== undefined
                                ?characters.map((item)=>{
                                    // let monsterActions = []
                                    // if(item.data){
                                    //     item.name = item.data.split("##")[1]
                                    //     item.hp = item.data.split("##")[2].split("//")[0]
                                    //     item.ca = item.data.split("##")[2].split("//")[1]
                                    //     item.pp = item.data.split("##")[2].split("//")[2]
                                    //     item.move = item.data.split("##")[2].split("//")[3]

                                        

                                    //     for(let i=3; i<item.data.split("##").length;i++){
                                    //         monsterActions.push(item.data.split("##")[i].split('//'))
                                    //     }
                                    // }
                                    return(
                                        
                                        <CharBox key={item.id}>

                                            <AiFillDelete 
                                                onContextMenu={()=>deleteForReal(item.id)}
                                                onClick={()=>removeFromList(item.id)}
                                                className='toTheRight' 
                                                />
                                            <strong>
                                                {
                                                    item.info
                                                    ?<>
                                                        {item.name}
                                                        &nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item.info} />
                                                    </>
                                                    :<a style={{color:'white', textDecoration:'none'}} target={'_blank'} href={'https://haodungeon.haode.com.br?idc='+campaingId+'&idch='+item.id}>{item.name}</a>
                                                } 

                                            <input type="text" value={item?.combbatIni} onChange={(e)=>alterInitiative(item.id, e.target.value)} onBlur={reorderList} /></strong>
                                            <table>
                                                <tr>
                                                    <td>HP</td>
                                                    <td>CA</td>
                                                    <td>PP</td>
                                                    <td>MOV</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input type="text" defaultValue={item?.hp} onBlur={(e)=>item.data?null:saveOnDb('hp',e.target.value, item.id)}/>
                                                    </td>
                                                    <td>{item.ca}</td>
                                                    <td>{   
                                                            item.wis >0
                                                            ?
                                                                item.pp
                                                                ?item.pp:parseInt(item.perception)===1?parseInt(setModifier(item.wis))+10+parseInt(item.proeficience)
                                                                :parseInt(setModifier(item.wis))+10
                                                            :''
                                                        }</td>
                                                    <td>{item.move}</td>
                                                </tr>
                                                {
                                                    item?.monsterActions!==undefined
                                                    ?item?.monsterActions.map(item=>{
                                                        return(
                                                            <tr key={item[0]}>
                                                                <td colSpan={4}>

                                                                    
                                                                
                                                                <p className="smallLetter">
                                                                        <strong>{item[0]} </strong>
                                                                    {/* HIT <span onClick={()=>rollDice('hitM',item[0],item[1])} className="pill">{item[1]}</span> 
                                                                    &nbsp; */}

                                                                    {
                                                                        /^\*/.test(item[1])
                                                                        ?null
                                                                        :
                                                                        <>
                                                                            HIT <span onClick={()=>rollDice('hitM',item[0],item[1])} className="pill">{item[1]}</span> 
                                                                            &nbsp;
                                                                        </>
                                                                        
                                                                    }
                                                                    
                                                                    
                                                                    {/* EF: <span 
                                                                            onClick={()=>rollDice('effectM','Efeito '+item[0]+" "+item[2] ,item[2])}
                                                                            className="pill">{item[2]}</span> {item.length>5?<span className="pill" onClick={()=>rollDice('effectM','Efeito '+item[0]+" "+item[3],item[3])}>{item[3]}</span>:null} 
                                                                    &nbsp;{item[item.length-2]} 
                                                                    &nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[item.length-1]} /> */}

{
                                                                (/^\*/.test(item[2]) && /^\*/.test(item[3]))
                                                                ?null
                                                                :'EF'
                                                            }

                                                            {   
                                                                /^\*/.test(item[2])
                                                                ?null
                                                                :<span 
                                                                onClick={()=>rollDice('effectM','Efeito '+item[0]+" "+item[2] ,item[2])}
                                                                className="pill">{item[2]}</span>
                                                            }

                                                            {   
                                                                /^\*/.test(item[3])
                                                                ?null
                                                                :<span 
                                                                onClick={()=>rollDice('effectM','Efeito '+item[0]+" "+item[3] ,item[3])}
                                                                className="pill">{item[2]}</span>
                                                            }

                                                            {   
                                                                /^\*/.test(item[4])
                                                                ?null
                                                                :item[4]
                                                            }

                                                            {   
                                                                /^\*/.test(item[item.length-1])
                                                                ?null
                                                                :<>&nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[item.length-1]} /></>
                                                            }
                                                                    
                                                                    
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }):null
                                                }
                                            </table>
                                        </CharBox>
                                    )
                                })
                                :null
                            }
                        </div>
                        <div style={{width:'100%', marginLeft:"2rem"}}>
                            <h5>Monstros <AiFillInfoCircle
                            title={
                            `COMO ADICIONAR MONSTROS
                            
                            1ª Linha: Nome do monstro
                            2ª Linha: Stats do monstro
                            3ª até penúltima Linha: Ações do Monstro
                            Última Linha: Informações adicionais

                            ##Nome
                            ##hp//ca//pp//move//ini//str//dex//con//int//wis//cha
                            ##Nome a ser exibido//+3//1d6+3//1d8+3//Cortante//Mais informações sobre a ação
                            ##Mais informações sobre o monstro

                            Exemplo:

                            ##Goblin
                            ##10//13//11//6//-1//0//+2//0//0//1//-1
                            ##Adaga//+3//1d6+3//1d8+3//Cortante//voce pode atacar com uma ou duas mãos
                            ##Besta de mão//+3//1d6+3//Perfurante//voce pode atacar com uma ou duas mãos
                            ##Bichinho verde
                            
                            
                            `
                            } /></h5>
                            
                            
                            <Notes style={{height: '20rem', marginTop:'.5rem', marginDown:'.5rem'}}>
                                {
                                    editingMonster !== 0
                                    ?<>
                                    <textarea 
                                        type="text" 
                                        className="textAreaNote"
                                        style={{border: '1px solid grey', margin:'.5rem 0', borderRadius:'.5rem'}}
                                        defaultValue={
                                            dataMonster===''
                                            ?`##Nome
                                            ##hp//ca//pp//move//ini//str//dex//con//int//wis//cha
                                            ##Nome a ser exibido//Bônus//Dano 1//Dano 2//Tipo de dano ou efeito//Mais informações sobre a ação
                                            ##Mais informações sobre o monstro. para valores vazios use asterisco`
                                            :dataMonster
                                            
                                        } 
                                        onBlur={(e)=>{
                                            saveMonster(e.target.value)
                                            
                                        }}
                                    />
                                    </>
                                    :<>
                                        {
                                            monsters !== undefined
                                            ?monsters?.map((item)=>{
                                                return(
                                                    <p
                                                        key={item.id}
                                                        style={{cursor:'pointer'}}
                                                        
                                                    ><span
                                                        onClick={()=>{
                                                            setDataMonster(item.data)
                                                            setEditingMonster(item.id)
                                                        }}
                                                        >{item.data.split('##')[1]}</span> <span onClick={()=>addMonster(item)}><MdOutlineAddCircleOutline style={{color:'white'}} /></span></p>
                                                )
                                            })
                                            :null
                                        }
                                        <FaEdit 
                                            className="toTheRight" 
                                            style={{color:"#f5f5f5", cursor:"pointer"}} 
                                            onClick={()=>setEditingMonster(-1)}
                                        />
                                    </>
                                }
                                
                            </Notes>
                            
                            <h5>
                                <span style={{cursor:'pointer'}} onClick={()=>setPanelDM('notes')}>Notas</span> | 
                                <span style={{cursor:'pointer'}} onClick={()=>setPanelDM('playlist')}> Playlist</span>&nbsp;<BsInfoCircleFill title={`Abra a Playlist no youtube
Clique em Compartilhar -> Incorporar
Do código exibido copie a url que está em "src"`} /> |
                                <span style={{cursor:'pointer'}} onClick={()=>setPanelDM('soundboard')}> Sons <BsInfoCircleFill title={`Atenção: usar o filtro de busca irá parar os sons que estiverem em execução`} /></span> 
                                </h5>
                            {/* {
                                panelDM==='notes'
                                ? */}
                                <Notes style={{height: '20rem', marginTop:'.5rem', marginDown:'.5rem', display:panelDM==='notes'?'flex':'none'}}>
                            {
                                            editingNoteDM
                                            ?<textarea 
                                                        type="text" 
                                                        className="textAreaNote"
                                                        style={{border: '1px solid grey', margin:'.5rem 0', borderRadius:'.5rem'}}
                                                        defaultValue={selectedCampaing?.notes} 
                                                        onChange={(e)=>setSelectedCampaing({...selectedCampaing, notes:e.target.value})} 
                                                        onBlur={(e)=>{
                                                            updateCampaing('notes',e.target.value)
                                                            
                                                        }}
                                                    />
                                                    :
                                                    <>
                                                        <FaEdit 
                                                            className="toTheRight" 
                                                            style={{color:"#f5f5f5", cursor:"pointer"}} 
                                                            onClick={()=>setEditingNoteDM(true)}
                                                        />
                                                        <pre>{selectedCampaing?.notes}</pre>
                                                    </>
                                                    
                                                }
                                    </Notes>
                                {/* :
                                panelDM==='playlist'
                                ? */}
                                <Notes style={{height: '20rem', marginTop:'.5rem', marginDown:'.5rem', display:panelDM==='playlist'?'flex':'none'}}>
                                    <p 
                                        onClick={editPlaylist}
                                        style={{textAlign:'right',fontSize:'.7rem',cursor:'pointer', marginBottom:'.1rem'}}>Editar</p>
                            
                                            <iframe 
                                                width="100%" 
                                                height="100%" 
                                                style={{borderRadius:'1rem'}}
                                                src={selectedCampaing?.playlist} 
                                                title="YouTube video player" frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                allowfullscreen
                                            >

                                            </iframe>
                                </Notes>
                                {/* : */}
                                <Notes style={{height: '20rem', marginTop:'.5rem', marginDown:'.5rem', display:panelDM==='soundboard'?'flex':'none'}}>
                                    <input 
                                        style={{border:'none', borderBottom:'1px solid grey', marginBottom:'.1rem', background:'transparent', color:"#f5f5f5"}} 
                                        type="text" 
                                        onChange={(e)=>setFilterSound(e.target.value)}
                                    />
                                    <p 
                                        onClick={addSound}
                                        style={{textAlign:'right',fontSize:'.7rem',cursor:'pointer', marginBottom:'.1rem'}}>Adicionar</p>

                                        {
                                            sounds
                                            ?sounds?.map(sound=>{
                                                return(
                                                    <div key={sound.id}>
                                                    <p
                                                        style={{textAlign:'left',fontSize:'.7rem', marginBottom:'.1rem'}}
                                                    >{sound.name} {parseInt(sound.inloop)===1?'(Loop)':null}</p>
                                                    <div style={{display:'flex'}}>
                                                        <audio 
                                                            style={{background:'transparent', width:'100%', height:'1.2rem', marginRight:'.2rem'}}
                                                            controls 
                                                            loop={sound.inloop==='1'?true:false}>
                                                            <source src={sound.url} type="audio/mpeg" />
                                                        </audio>
                                                        <BsTrashFill style={{cursor:'pointer'}} onClick={()=>deleteSound(sound.id)} />
                                                    </div>
                                                    <hr style={{border:'1px solid #595858'}} />
                                                    </div>
                                                )
                                            }
                                            )
                                            :null
                                        }

                                </Notes>
                            {/* } */}
                            
                            
                        </div>
                       
                                
                        <RollBox  isMobile={isMobile} reloadRolls={reloadRolls} />
                    </div>
                
                :screen==='campaing'
                ?<Content>
                    <SelectCharacter>
                    <h2 style={{marginBottom:'1rem'}}>Selecione seu personagem</h2>
                    {
                        characters!==undefined
                        ?characters.map((item)=>{
                            return(
                                    <div key={item.id} onClick={()=>window.location.href="https://haodungeon.haode.com.br?idc="+campaingId+"&idch="+item?.id}>
                                        <span style={{display:'flex', alignItems:'center'}}>
                                        {
                                            (item?.avatar_url !== undefined && item?.avatar_url !==null && item?.avatar_url !=='')
                                            ?<img 
                                                onContextMenu={alterAvatar}
                                                src={item?.avatar_url} width={40} />
                                            :<img
                                                onContextMenu={alterAvatar} 
                                                src="https://haodungeon.haode.com.br/favicon.ico" width={40} />
                                            
                                        }
                                        <span style={{display:'flex', flexDirection:'column', marginLeft:'1rem'}}>
                                            <p>{item.name}</p>
                                        <span className="smallLine"><p >{item.race}</p> - <p>{item.class}</p></span>
                                        </span>
                                        </span>
                                        
                                    </div>
                            )
                        })
                        :null
                    }

                    </SelectCharacter>
                    
                </Content>
                :screen==='character'
                ?<div style={{display:'flex', flexDirection:'row', width:'100%', margin:'8px'}}>
                    <Content className='characterSheet'>
                        <div className='thisClass' style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
                        
                            {
                                (character?.avatar_url !== undefined && character?.avatar_url !==null && character?.avatar_url !=='')
                                ?<img 
                                    onContextMenu={alterAvatar}
                                    onClick={getCharacter}
                                    src={character?.avatar_url} width={50} />
                                :<img
                                    onContextMenu={alterAvatar} 
                                    onClick={getCharacter}
                                    src="https://haodungeon.haode.com.br/favicon.ico" width={50} />
                                
                            }

                            <span style={{display:'flex',width:'100%', paddingLeft:'1rem', alignItems:'center'}}>
                                <input type="text" className="bigInfo" value={character?.name} onChange={(e)=>setCharacter({...character, name:e.target.value})} onBlur={(e)=>saveOnDb('name',e.target.value)}/>
                            </span>

                            <h4 className='otherClass this35' style={{display:'flex', alignItems:'center', marginRight:'.5rem'}}>
                                {
                                    parseInt(character?.inspiration)===1
                                    ?<FaRegDotCircle style={{cursor:'pointer'}} onClick={alterInspiration} title='Inspiração' />
                                    :<FaRegCircle style={{cursor:'pointer'}} onClick={alterInspiration}  title='Inspiração' />
                                }

                            </h4>
                            
                            {/* <p className="verySmallP"> Inspiração &nbsp;&nbsp;
                            {
                                parseInt(character?.inspiration)===1
                                ?<FaRegDotCircle style={{cursor:'pointer'}} onClick={alterInspiration} />
                                :<FaRegCircle style={{cursor:'pointer'}} onClick={alterInspiration} />
                            }
                            
                            
                            </p> */}
                            {
                                selectedCampaing!==undefined && selectedCampaing?.vttlink !== null
                                ?selectedCampaing?.vttlink.length>10
                                    ?<>
                                    <a style={{color:'white', textDecoration:'none', display:'flex', width:'25px', alignItems:'center', justifyContent:'center', fontSize:'2rem'}} 
                                        target={'_blank'} 
                                        href={'https://haodungeon.haode.com.br?idc=tabletop&ridc='+campaingId+'&idch='+characterId+'&idtt='+vTTLink}
                                        // href={selectedCampaing?.vttlink}
                                    >
                                        <BiWorld title='Abrir VTT'/>
                                    </a>
                                    <a style={{color:'white', textDecoration:'none', display:'flex', width:'25px', alignItems:'center', justifyContent:'center', fontSize:'2rem'}} 
                                        target={'_blank'} 
                                        href={'https://meet.jit.si/'+campaingId+'#userInfo.displayName="'+character?.name+'"&config.prejoinConfig.enabled=false&config.startWithAudioMuted=true&config.startWithVideoMuted=true'}
                                    >
                                    <AiFillVideoCamera />
                                    </a>
                                    </>
                                    :null
                                :null
                            }
                            
                            
                        </div>
                        <div className='plusClass' style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
                            <h4 className='otherClass'>
                                Raça 
                                <input type="text" className="smallInfo" value={character?.race} onChange={(e)=>setCharacter({...character, race:e.target.value})} onBlur={(e)=>saveOnDb('race',e.target.value)}/>
                            </h4>
                            <h4 className='otherClass'>
                                Classe
                                <input type="text" className="smallInfo" value={character?.class} onChange={(e)=>setCharacter({...character, class:e.target.value})} onBlur={(e)=>saveOnDb('class',e.target.value)} />
                            </h4>
                            <h4 className='otherClass'>XP
                                <input type="number" className="smallInfo" value={character?.xp} onChange={(e)=>setCharacter({...character, xp:e.target.value})} onBlur={(e)=>saveOnDb('xp',e.target.value)}/>
                            </h4>
                            <h4 className='otherClass'>Nível
                                <input type="number" className="smallInfo" value={character?.level} onChange={(e)=>setCharacter({...character, level:e.target.value})} onBlur={(e)=>saveOnDb('level',e.target.value)}/>
                            </h4>
                            {/* <h4 className='otherClass'>
                                Inspiração &nbsp;
                                {
                                    parseInt(character?.inspiration)===1
                                    ?<FaRegDotCircle style={{cursor:'pointer'}} onClick={alterInspiration} />
                                    :<FaRegCircle style={{cursor:'pointer'}} onClick={alterInspiration} />
                                }

                            </h4> */}
                            
                            
                        </div>
                        {/* <div className='plusClass' style={{display:'flex', width:'100%', justifyContent:'space-between'}}> */}
                            {/* <h4 className='otherClass'>XP
                                <input type="number" className="smallInfo" value={character?.xp} onChange={(e)=>setCharacter({...character, xp:e.target.value})} onBlur={(e)=>saveOnDb('xp',e.target.value)}/>
                            </h4>
                            <h4 className='otherClass'>Nível
                                <input type="number" className="smallInfo" value={character?.level} onChange={(e)=>setCharacter({...character, level:e.target.value})} onBlur={(e)=>saveOnDb('level',e.target.value)}/>
                            </h4> */}
                            {/* <h4 className='otherClass'>
                                Inspiração &nbsp;
                                {
                                    parseInt(character?.inspiration)===1
                                    ?<FaRegDotCircle style={{cursor:'pointer'}} onClick={alterInspiration} />
                                    :<FaRegCircle style={{cursor:'pointer'}} onClick={alterInspiration} />
                                }

                            </h4> */}
                            {/* <h4 className='otherClass'>HP
                                <input type="number" className="smallInfo" value={character?.hp} onChange={(e)=>setCharacter({...character, hp:e.target.value})} onBlur={(e)=>saveOnDb('hp',e.target.value)}/>
                            </h4> */}

                        {/* </div> */}
                        <div className='plusClass' style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
                            <h4 className='otherClass'>Pro
                                <input type="number" className="smallInfo" value={character?.proeficience} onChange={(e)=>setCharacter({...character, proeficience:e.target.value})} onBlur={(e)=>saveOnDb('proeficience',e.target.value)}/>
                            </h4>
                            <h4 className='otherClass'>Desl
                                <input type="number" className="smallInfo" value={character?.move} onChange={(e)=>setCharacter({...character, move:e.target.value})} onBlur={(e)=>saveOnDb('move',e.target.value)}/>
                            </h4>
                            <h4 className='otherClass'>Ini
                                <input type="number" className="smallInfo" value={character?.initiative} onChange={(e)=>setCharacter({...character, initiative:e.target.value})} onBlur={(e)=>saveOnDb('initiative',e.target.value)}/>
                            </h4>
                            <h4>CA
                                <input type="number" className="smallInfo" value={character?.ca} onChange={(e)=>setCharacter({...character, ca:e.target.value})} onBlur={(e)=>saveOnDb('ca',e.target.value)}/>
                            </h4>
                            <h4 className='otherClass'>HP
                                <input type="number" className="smallInfo" value={character?.hp} onChange={(e)=>setCharacter({...character, hp:e.target.value})} onBlur={(e)=>saveOnDb('hp',e.target.value)}/>
                            </h4>
                        </div>
                        <div className='plusClass infoCond spaceOnMobile' style={{display:'flex', width:'100%'}}>
                            <p style={{fontWeight:'bold'}}>Info e condições &nbsp;&nbsp;</p>
                            <input type="text" value={character?.conditions} onChange={(e)=>setCharacter({...character, conditions:e.target.value})} onBlur={(e)=>saveOnDb('conditions',e.target.value)}/>
                        </div>
                        
                        {/* <div>
                            
                        </div> */}
                        <div style={{display:'flex', width:'100%', justifyContent:'space-around', marginTop:'1rem'}}>
                            <AttBox>
                                <strong>FOR</strong>
                                <Modifier onClick={()=>rollDice('test','Teste For',character?.str)}>{setModifier(character?.str)}</Modifier>
                                <input type="number" className="smallInfoNoLine" value={character?.str} onChange={(e)=>setCharacter({...character, str:e.target.value})} onBlur={(e)=>saveOnDb('str',e.target.value)}/>
                            </AttBox>

                            <AttBox>
                                <strong>DES</strong>
                                <Modifier onClick={()=>rollDice('test','Teste Des',character?.dex)}>{setModifier(character?.dex)}</Modifier>
                                <input type="number" className="smallInfoNoLine" value={character?.dex} onChange={(e)=>setCharacter({...character, dex:e.target.value})} onBlur={(e)=>saveOnDb('dex',e.target.value)}/>
                            </AttBox>

                            <AttBox>
                                <strong>CON</strong>
                                <Modifier onClick={()=>rollDice('test','Teste Con',character?.con)}>{setModifier(character?.con)}</Modifier>
                                <input type="number" className="smallInfoNoLine" value={character?.con} onChange={(e)=>setCharacter({...character, con:e.target.value})} onBlur={(e)=>saveOnDb('con',e.target.value)}/>
                            </AttBox>

                            <AttBox>
                                <strong>INT</strong>
                                <Modifier onClick={()=>rollDice('test','Teste Int',character?.inte)}>{setModifier(character?.inte)}</Modifier>
                                <input type="number" className="smallInfoNoLine" value={character?.inte} onChange={(e)=>setCharacter({...character, inte:e.target.value})} onBlur={(e)=>saveOnDb('inte',e.target.value)}/>
                            </AttBox>

                            <AttBox>
                                <strong>SAB</strong>
                                <Modifier onClick={()=>rollDice('test','Teste Sab',character?.wis)}>{setModifier(character?.wis)}</Modifier>
                                <input type="number" className="smallInfoNoLine" value={character?.wis} onChange={(e)=>setCharacter({...character, wis:e.target.value})} onBlur={(e)=>saveOnDb('wis',e.target.value)}/>
                            </AttBox>

                            <AttBox>
                                <strong>CAR</strong>
                                <Modifier onClick={()=>rollDice('test','Teste Car',character?.cha)}>{setModifier(character?.cha)}</Modifier>
                                <input type="number" className="smallInfoNoLine" value={character?.cha} onChange={(e)=>setCharacter({...character, cha:e.target.value})} onBlur={(e)=>saveOnDb('cha',e.target.value)}/>
                            </AttBox>
                        </div>
                        <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:'1rem'}}>
                            <div>
                            <DiceList rollDice={rollDice} />
                                <h5>Perícias</h5>
                                <ul className="diferentList">
                                    {
                                        skills.map((item)=>{
                                            return(
                                                <li key={item.id}>
                                                {parseInt(character?.[item.type])===1
                                                ?<BsCircleHalf title='Meia proeficiência' style={{cursor:'pointer'}} onClick={()=>setSkill(item.type)} />
                                                :parseInt(character?.[item.type])===2
                                                ?<BsCircleFill title='Proeficiente' style={{cursor:'pointer'}} onClick={()=>setSkill(item.type)} />
                                                :parseInt(character?.[item.type])===3
                                                ?<IoIosArrowDropupCircle title='Expert (Proeficiênca x 2)' style={{cursor:'pointer'}} onClick={()=>setSkill(item.type)} />
                                                :<BsCircle title='Não proeficiente' style={{cursor:'pointer'}} onClick={()=>setSkill(item.type)} />
                                                }
                                                
                                                    <span>
                                                        <p onClick={()=>rollDice('test',item?.name,character?.[item.mod],parseInt(character?.[item.type]))}>&nbsp;{item.name}</p>
                                                        <p>{
                                                            parseInt(character?.[item.type])===0
                                                            ?setModifier(character?.[item.mod])
                                                            :parseInt(character?.[item.type])===1
                                                            ?parseInt(setModifier(character?.[item.mod]))+Math.round(parseInt(character?.proeficience)/2)<0
                                                                ?"- "+(parseInt(setModifier(character?.[item.mod]))+Math.round(parseInt(character?.proeficience)/2))
                                                                :"+ "+(parseInt(setModifier(character?.[item.mod]))+Math.round(parseInt(character?.proeficience)/2))
                                                                :parseInt(character?.[item.type])===2
                                                                ?parseInt(setModifier(character?.[item.mod]))+Math.round(parseInt(character?.proeficience)/2)<0
                                                                    ?"- "+(parseInt(setModifier(character?.[item.mod]))+parseInt(character?.proeficience))
                                                                    :"+ "+(parseInt(setModifier(character?.[item.mod]))+parseInt(character?.proeficience))
                                                                :parseInt(setModifier(character?.[item.mod]))+parseInt(character?.proeficience)<0
                                                                    ?"- "+(parseInt(setModifier(character?.[item.mod]))+parseInt(character?.proeficience)*2)
                                                                    :"+ "+(parseInt(setModifier(character?.[item.mod]))+parseInt(character?.proeficience)*2)
                                                            
                                                            }</p>
                                                    </span>
                                                </li>
                                            )

                                        })
                                    }
                                    
                                    
                                </ul>
                                {/* <h5>Outras perícias</h5> */}
                                {/* <textarea 
                                    type="text" 
                                    className="textAreaNote"
                                    style={{border: '1px solid grey', margin:'.5rem 0', borderRadius:'.5rem'}}
                                    defaultValue={character?.actions} 
                                    onChange={(e)=>setCharacter({...character, actions:e.target.value})} 
                                    onBlur={(e)=>{
                                        saveActions(e.target.value)
                                        
                                    }}
                                /> */}

                                {/* <textarea
                                        typeof='text'
                                        // className="textAreaSkills" 
                                        className="textAreaNote"
                                        defaultValue={character?.other_skills} 
                                        onChange={(e)=>setCharacter({...character, other_skills:e.target.value})} 
                                        onBlur={(e)=>saveOtherSkills(e.target.value)}
                                /> */}
                                            
                                
                                    
                            </div>
                            <div className='thisBox'>
                            
                                <h5>
                                    <span style={{cursor:'pointer'}} onClick={()=>setPanel('actions')}>Ações 
                                    &nbsp;<AiFillInfoCircle title={
                                    `COMO ADICIONAR AÇÕES
                                    Siga o padrão:
                                    ##Nome a ser exibido//Bônus na rolagem//Dano ou efeito 1//Dano ou efeito 2//Tipo de dano ou efeito//Mais informações sobre a ação
                                    
                                    Exemplos:
                                    ##Espada longa//+3//1d8+3//1d10+3//Cortante//Marcia, Versátil

                                    ##Bola de fogo
                                    //*
                                    //12d6
                                    //*
                                    //*
                                    //Cada criatura numa esfera, com 6
                                    metros de raio, centrada na conta, deve realizar um teste
                                    de resistência de Destreza. Uma criatura sofre dano igual
                                    ao total de dano acumulado se falhar na resistência, ou
                                    metade do total se obtiver sucesso.

                                    Obs: com exceção de Nome todas as informações são opcionais, coloque * para informações vazias
                                    `} /> 
                                    {/* {
                                        theDMIsOn==='dnd@2022'
                                        ? */}
                                        <a style={{color:'white', textDecoration:'none'}} target={'_blank'} href={'https://haodungeon.haode.com.br?idc=actions'}>
                                            &nbsp;<RxMagnifyingGlass/> 
                                        </a>
                                        {/* :null
                                    } */}
                                    
                                    
                                    </span>
                                    &nbsp;| 
                                    <span style={{cursor:'pointer'}} onClick={()=>setPanel('inventory')}> Inventário </span>
                                    | 
                                    <span style={{cursor:'pointer'}} onClick={()=>setPanel('notes')}> Anotações</span>
                                </h5>
                                {
                                    panel==='actions'
                                    ?<Notes>
                                        {
                                            editingActions||!character?.actions?.length>0
                                            ?<textarea 
                                                type="text" 
                                                className="textAreaNote"
                                                style={{border: '1px solid grey', margin:'.5rem 0', borderRadius:'.5rem'}}
                                                defaultValue={character?.actions} 
                                                onChange={(e)=>setCharacter({...character, actions:e.target.value})} 
                                                onBlur={(e)=>{
                                                    saveActions(e.target.value)
                                                    
                                                }}
                                            />
                                            :
                                            actions !== undefined
                                            ?actions.map((item)=>{
                                                return(
                                                    <>
                                                        <ActionItem>
                                                            <strong style={{lineHeight:'1.7rem'}}>{item[0]}
                                                                {   
                                                                    /^\*/.test(item[5])
                                                                    ?null
                                                                    :<>&nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[5]} /></>
                                                                }
                                                            </strong>
                                                            <p>
                                                                {
                                                                    /^\*/.test(item[1])
                                                                    ?null
                                                                    :
                                                                    <>
                                                                        HIT <span onClick={()=>rollDice('hit',item[0],item[1])} className="pill">{item[1]}</span> 
                                                                        &nbsp;
                                                                    </>
                                                                    
                                                                }

                                                                {
                                                                    (/^\*/.test(item[2]) && /^\*/.test(item[3]))
                                                                    ?null
                                                                    :'EF'
                                                                }

                                                                {   
                                                                    /^\*/.test(item[2])
                                                                    ?null
                                                                    :<span 
                                                                    onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[2] ,item[2])}
                                                                    className="pill">{item[2]}</span>
                                                                }

                                                                {   
                                                                    /^\*/.test(item[3])
                                                                    ?null
                                                                    :<span 
                                                                    onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[3] ,item[3])}
                                                                    className="pill">{item[3]}</span>
                                                                }

                                                                {   
                                                                    /^\*/.test(item[4])
                                                                    ?null
                                                                    :item[4]
                                                                }

                                                                
                                                                </p>
                                                                
                                                                {/* EF:  {item.length>5?<span className="pill" onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[3],item[3])}>{item[3]}</span>:null}  */}
                                                                {/* &nbsp;{item[item.length-2]}  */}
                                                                {/* &nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[item.length-1]} /></p> */}
                                                        </ActionItem>
                                                        {/* <FaEdit 
                                                            className="toTheRight" 
                                                            style={{color:"#f5f5f5", cursor:"pointer"}} 
                                                            onClick={()=>setEditingActions(true)}
                                                        /> */}
                                                    </>
                                                )
                                            })
                                            :null
                                            // <FaEdit 
                                            //     className="toTheRight" 
                                            //     style={{color:"#f5f5f5", cursor:"pointer"}} 
                                            //     onClick={()=>setEditingActions(true)}
                                            // />
                                        }
                                        {
                                            !editingActions
                                            ?<FaEdit 
                                                className="toTheRight" 
                                                style={{color:"#f5f5f5", cursor:"pointer"}} 
                                                onClick={()=>setEditingActions(true)}
                                            />
                                            :null
                                        }
                                        
                                    </Notes>
                                    :panel==='inventory'
                                    ?<Notes>
                                            
                                        {
                                            editingInventory||!character?.inventory?.length>0
                                            ?<textarea 
                                                type="text" 
                                                className="textAreaNote"
                                                style={{border: '1px solid grey', margin:'.5rem 0', borderRadius:'.5rem'}}
                                                defaultValue={character?.inventory} 
                                                onChange={(e)=>setCharacter({...character, inventory:e.target.value})} 
                                                onBlur={(e)=>{
                                                    saveInventory(e.target.value)
                                                    
                                                }}
                                            />
                                            :
                                            <>
                                                <FaEdit 
                                                    className="toTheRight" 
                                                    style={{color:"#f5f5f5", cursor:"pointer"}} 
                                                    onClick={()=>setEditingInventory(true)}
                                                />
                                                <pre>{character?.inventory}</pre>
                                            </>
                                            
                                        }
                                        
                                        
                                            
                                    </Notes>
                                    :<Notes>
                                        
                                        
                                        {
                                            editingNote||!character?.notes?.length>0
                                            ?<textarea 
                                                type="text" 
                                                className="textAreaNote"
                                                style={{border: '1px solid grey', margin:'.5rem 0', borderRadius:'.5rem'}}
                                                defaultValue={character?.notes} 
                                                onChange={(e)=>setCharacter({...character, notes:e.target.value})} 
                                                onBlur={(e)=>{
                                                    saveNotes(e.target.value)
                                                    
                                                }}
                                            />
                                            :
                                            <>
                                                <FaEdit 
                                                    className="toTheRight" 
                                                    style={{color:"#f5f5f5", cursor:"pointer"}} 
                                                    onClick={()=>setEditingNote(true)}
                                                />
                                                <pre>{character?.notes}</pre>
                                                
                                            </>
                                            
                                        }

                                        
                                        
                                        
                                            
                                    </Notes>
                                }
                                
                                <div className='tableDetails' style={{width:'100%'}}>
                                    <input 
                                        type="text" 
                                        className='inputLimited'
                                        placeholder='Habilidades limitadas' 
                                        defaultValue={character?.limited_use} 
                                        onBlur={(e)=>saveOnDb('limited_use',e.target.value)}
                                    />
                                    <h5>Slots usados</h5>
                                    {
                                        character!==null
                                        ?<table className='inputSlot' style={{width:'100%'}}>
                                        <tr>
                                            <td style={{width:'50%'}}>
                                                1&nbsp;
                                                <input type="text" 
                                                    defaultValue={character?.slot1used} 
                                                    onBlur={(e)=>saveOnDb('slot1used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot1} 
                                                    onBlur={(e)=>saveOnDb('slot1',e.target.value)}
                                                />
                                                </td>
                                            <td style={{width:'50%'}}>6&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot6used} 
                                                    onBlur={(e)=>saveOnDb('slot6used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot6} 
                                                    onBlur={(e)=>saveOnDb('slot6',e.target.value)}
                                                />
                                                </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'50%'}}>2&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot2used} 
                                                    onBlur={(e)=>saveOnDb('slot2used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot2} 
                                                    onBlur={(e)=>saveOnDb('slot2',e.target.value)}
                                                />
                                                </td>
                                            <td style={{width:'50%'}}>7&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot7used} 
                                                    onBlur={(e)=>saveOnDb('slot7used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot7} 
                                                    onBlur={(e)=>saveOnDb('slot7',e.target.value)}
                                                />
                                                </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'50%'}}>3&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot3used} 
                                                    onBlur={(e)=>saveOnDb('slot3used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot3} 
                                                    onBlur={(e)=>saveOnDb('slot3',e.target.value)}
                                                />
                                                </td>
                                            <td style={{width:'50%'}}>8&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot8used} 
                                                    onBlur={(e)=>saveOnDb('slot8used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot8} 
                                                    onBlur={(e)=>saveOnDb('slot8',e.target.value)}
                                                />
                                                </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'50%'}}>4&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot4used} 
                                                    onBlur={(e)=>saveOnDb('slot4used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot4} 
                                                    onBlur={(e)=>saveOnDb('slot4',e.target.value)}
                                                />
                                                </td>
                                            <td style={{width:'50%'}}>9&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot9used} 
                                                    onBlur={(e)=>saveOnDb('slot9used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot9} 
                                                    onBlur={(e)=>saveOnDb('slot9',e.target.value)}
                                                />
                                                </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:'50%'}}>5&nbsp;
                                            <input type="text" 
                                                    defaultValue={character?.slot5used} 
                                                    onBlur={(e)=>saveOnDb('slot5used',e.target.value)}
                                                /> /
                                                <input type="text" 
                                                    defaultValue={character?.slot5} 
                                                    onBlur={(e)=>saveOnDb('slot5',e.target.value)}
                                                />
                                                </td>
                                            <td></td>
                                        </tr>
                                    </table>
                                    :null
                                    }
                                </div>
                            </div>
                            
                            
                        </div>
                        
                    </Content>
                    <div style={{display:'flex', justifyContent:'center', flexDirection:'row', width:'30%'}}>
                        <RollBox isMobile={isMobile} reloadRolls={reloadRolls} />
                    </div>
                    
                </div>
                :screen==='select'
                    ?theDMIsOn !== 'dnd@2022'
                    ?<SelectCharacter style={{marginTop:'5rem'}}>
                        <h2 style={{marginTop:'2rem'}}>Bem-vindo, Mestre!</h2>
                        <h5>Digite a senha</h5>
                        <input onChange={(e)=>setTheDMIsOn(e.target.value)} style={{background:'#313131', border:'none', borderRadius:'1rem', textAlign:'center', color:'white'}} type="password" />
                    </SelectCharacter>
                    :<Content>
                        <SelectCharacter>
                        <h2 style={{marginBottom:'1rem'}}>Selecione sua campanha <MdOutlineAddCircleOutline style={{cursor:'pointer'}} onClick={addCampaing} /></h2>
                        {
                            campaings!==undefined
                            ?campaings.map((item)=>{
                                return(
                                        <div key={item.id} onClick={()=>{
                                            window.location.href="https://haodungeon.haode.com.br?idc="+item?.id+"&idch=admin"
                                        }}>
                                            <p>{item.name}</p>
                                        </div>
                                )
                            })
                            :null
                        }

                        </SelectCharacter>
                        
                    </Content>

                :<Content style={{alignItems:'center', marginTop:'1rem', minHeight:'90vh'}}>
                    <img width={400} src={Logo} alt="" />
                        <p 
                        onClick={()=>setLetsBora(letsBora+1)}
                        style={{marginTop:'4rem', textAlign:'center'}}>It's dangerous to go alone...</p>
                        <input 

                            onChange={(e)=>setTypedId(e.target.value)}

                            style={{
                                border:'none',
                                background: '#525252',
                                borderBotton:'1px solid white',
                                width:'200px',
                                height:"1.7rem",
                                borderRadius:"1rem",
                                color: 'white',
                                marginTop:'2rem',
                                paddingLeft:'1rem'
                            }}
                        type="text" />

                        <button
                            onClick={()=>{
                                window.location.href="https://haodungeon.haode.com.br?idc="+typedId
                            }}
                            style={{
                                border:"1px solid white",
                                background:"transparent",
                                cursor:'pointer',
                                marginTop:'1rem',
                                padding:'.5rem',
                                color:'white',
                                borderRadius:'1rem'

                            }}
                        >I'm brave!</button>

                        <button
                            style={{
                                marginTop:"5rem",
                                border:"1px solid white",
                                background:"transparent",
                                cursor:'pointer',
                                padding:'.5rem',
                                color:'white',
                                borderRadius:'1rem'
                            }}
                            onClick={oneShot}
                            >
                            OneShot Table
                        </button>
        
                </Content>
            }

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                overlayClassName="react-modal-overlay"
                className="react-modal-content"
                contentLabel="Ferramentas"
            >
                <div>

                    <h3>Ferramentas</h3>
                    &nbsp;&nbsp;<a href="https://5e.tools/" target={'new'}>https://5e.tools/</a>
                    <br /><br />
                    <h3>Calculadora de encontros</h3>
                    &nbsp;&nbsp;<a href="https://koboldplus.club/#/encounter-builder" target={'new'}>https://koboldplus.club/#/encounter-builder</a>

                    <br /><br />
                    <h3>Magias</h3>
                    &nbsp;&nbsp;<a href="https://avribacki.gitlab.io/magias5e/" target={'new'}>https://avribacki.gitlab.io/magias5e/</a><br />
                    &nbsp;&nbsp;<a href="https://dnd5spells.rpgist.net/pt-BR/spells" target={'new'}>https://dnd5spells.rpgist.net/pt-BR/spells</a>

                    <br /><br />
                    <h3>Criar tokens</h3>
                    &nbsp;&nbsp;<a href="https://rolladvantage.com/tokenstamp/" target={'new'}>https://rolladvantage.com/tokenstamp/</a>

                    <br /><br />
                    <h3>Músicas e sons</h3>
                    &nbsp;&nbsp;<a href="https://tabletopaudio.com/" target={'new'}>https://tabletopaudio.com/</a><br />
                    &nbsp;&nbsp;<a href="https://tabletopy.com/" target={'new'}>https://tabletopy.com/</a>

                    <br /><br />
                    <h3>Guia rápido</h3>
                    &nbsp;&nbsp;<a href="https://diogoan.github.io/dnd5e-quickref/" target={'new'}>https://diogoan.github.io/dnd5e-quickref/</a>

                    <br /><br />
                    <h3>VTT</h3>
                    &nbsp;&nbsp;<a href="https://www.owlbear.rodeo/" target={'new'}>https://www.owlbear.rodeo/</a>
                    <br /><br />
                    <h3>Geradores</h3>
                    &nbsp;&nbsp;<a href="https://donjon.bin.sh/" target={'new'}>https://donjon.bin.sh/</a>
                    <br /><br />
                    <h3>Criar mapas</h3>
                    &nbsp;&nbsp;<a href="https://inkarnate.com/" target={'new'}>https://inkarnate.com/</a>


                </div>
                
            </Modal>
            

            
        </Container>
    )
}