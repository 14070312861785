import styled from 'styled-components'

export const Container = styled.div`

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    


    .tableDetails{
        input{
            position: inherit !important;
        }
    }

    .vttlink{
        margin-top: 1rem;
        width: 70%;
        background: transparent;
        color: #f5f5f5;
        border: 0;
        border-bottom: 1px solid #f5f5f5;
    }

    .thisBox{
        width:100%;
        margin-left:2rem;
        @media only screen and (max-width: 720px)  {
            margin: 0;
            width:auto;
        }
    }

    .characterSheet{
        width: 100%;

        input{
            border: none;
        }

        .thisClass{
            display: flex;
            flex-direction: row;
        }

        .otherClass{

            @media only screen and (max-width: 720px)  {
                margin-top: .5rem;
            }

        }

        .spaceOnMobile{

            @media only screen and (max-width: 720px)  {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

        }

        .infoCond{
            box-sizing: border-box;
            input{
                width: 80%;
                font-size: .7rem;
                background: transparent;
                border: none;
                color: #f5f5f5;
            }
            p{
                font-size: .7rem;
                color: #f5f5f5;
            }
            @media only screen and (max-width: 720px)  {
                display: flex;
                flex-direction: column;
                p{
                    width: 100%;
                }
                input{
                    width: 90%;
                    position: absolute;
                    left: 1rem;
                    margin-top: 1rem;
                }
            }

        }

        .this35{

            @media only screen and (max-width: 720px)  {
                height: 35px;
            }

        }

        .plusClass{

            @media only screen and (min-width: 720px)  {
                margin-top: 1rem;
                border: 1px solid #424141;
                border-radius: 1rem;
                padding: .5rem;
            }

        }

        @media only screen and (max-width: 720px)  {
            padding: 1rem;
            width: 100% !important;
            span{
                width: 100% !important;
            }
            div{
                display: flex;
                flex-direction: column;
                @media only screen and (max-width: 720px)  {
                    min-width: 250px;
                    width: unset;
                }
                min-width: 350px;
                width: 100%;
                input{
                    position: absolute;
                    width: 80%;
                    right: 0;
                }
            }
        }
    }

    .inputSlot{
        td{
            input{
                background: transparent;
                display: inline;
                width: 2rem;
                border: 0;
                border-bottom: 1px solid white;
                text-align: center;
                color: #f5f5f5;
            }
        }
        
    }

    .toTheRight{
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
        }


    .bigInfo{
        background: transparent;
        /* text-align: center; */
        /* width: 20%; */
        font-size: 1.2rem;
        border: none;
        margin-left: .2rem;
        color: #f5f5f5;
    }
    
`;


export const Content = styled.div`

.inputLimited{

    border: 0 !important;
    border-bottom: 1px solid grey !important;
    color: #f5f5f5 !important;
    width: 100% !important;
    margin-top: 1rem;
    background: transparent;

    }

    @media only screen and (max-width: 720px)  {
     width: 100% ;
     margin-left: 0;
     padding: 0;
    }

    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    position: relative;
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
    background: #313131;
    padding: 1.5rem;
    border-radius: 1rem;

    ul{
        list-style-type: none;
    }

    pre{
        font-size: .7rem;
    }

    .diferentList{

        margin-left:0;
        width: 100%;
        padding-left: 0;

        li{
            display: flex;
            width: 100%;
            justify-content: space-between;
            
            span{
                display: flex;
                width: 90%;
                justify-content: space-between;
                cursor: pointer;

                &:hover{
                    background: #525252;
                }
            }
            

            /* div{
                width: 70%;
            } */

            

            
            
        }

    }

    .verySmallInfo{
        background: transparent;
        width: 5rem;
        font-size: .7rem;
        border: none;
        border-bottom: 1px solid #525252;
        margin-left: .2rem;
        color: #f5f5f5;
    }

    .verySmallP{
        background: transparent;
        width: 5rem;
        font-size: .7rem;
        border: none;
        margin-left: .2rem;
        color: #f5f5f5;
    }

    .smallInfo{
        background: transparent;
        text-align: center;
        width: 5rem;
        font-size: 1rem;
        border: none;
        border-bottom: 1px solid #525252;
        margin-left: .2rem;
        color: #f5f5f5;
    }

    .textAreaSkills{

        background: #525252;
        /* text-align: center; */
        width: 15rem;
        height: 5rem;
        font-size: .7rem;
        border: none;
        margin-left: .2rem;
        color: #f5f5f5;
        border-radius: 1rem;
        padding: .5rem;

    }

    .smallInfoNoLine{
        background: transparent;
        text-align: center;
        width: 5rem;
        font-size: 1rem;
        border: none;
        margin-left: .2rem;
        color: #f5f5f5;
    }

    .bigInfo{
        background: transparent;
        /* text-align: center; */
        /* width: 20%; */
        font-size: 1.2rem;
        border: none;
        margin-left: .2rem;
        color: #f5f5f5;
    }
`

export const AttBox = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 1rem;
    border: 1px solid #f5f5f5;

    @media only screen and (max-width: 720px)  {
            margin-bottom: .5rem;
            input{
                position: inherit !important;
            }
        }

`;

export const Modifier = styled.h2`

    cursor: pointer;

`;

export const Notes = styled.div`

        @media only screen and (min-width: 1024px)  {
            max-width: 500px;
        }
                

        /* @media only screen and (max-width: 720px)  {
            max-width: 100%;
        } */
        min-width: 300px;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        position: relative;

        background: #525252;
        /* text-align: center; */
        /* width: 100%; */
        /* max-width: 336px; */
        height: 20rem;
        font-size: 1rem;
        border: none;
        /* margin-left: .2rem; */
        color: #f5f5f5;
        border-radius: 1rem;
        padding: 1rem;

        .toTheRight{
            position: absolute;
            right: 1rem;
            top: 1rem;
        }

        .class80{
            width: 80%;
            text-align: left;
            word-wrap: break-word;
            word-wrap: break-word;
            
        }

        .textAreaNote{
            width: 100%;
            height: 100%;
        }

        .miniOptions{
            
            display: flex;
            p{
                font-size: .7rem;
            }
        }


`;

export const ActionItem = styled.div`
    margin-bottom: 1rem;
    font-size: .8rem;

    strong{
        line-height: 1.3rem;
    }

    .pill{
        border: 1px solid #f5f5f5;
        padding: .3rem;
        border-radius: 1rem;
        cursor: pointer;
        &:hover{
            background: #313131;
        }
    }

`;

export const SelectCharacter = styled.div`

    display: flex;
    min-height: 50vh;
    width: 50%;
    align-items: center;
    flex-direction: column;
    background: #525252;
    padding: 1rem;
    border-radius: 1rem;
    margin: 0 auto;
    
    div{
        margin-bottom: 1rem;
        background: #313131;
        border-radius: 1rem;
        padding: 1rem;
        width: 50%;
        cursor: pointer;
        transition: all .5s;

        &:hover{
            scale: 1.05;
            transition: all .5s;
        }

        .smallLine{
            display: flex;
            margin-top: .5rem;
            font-size: .7rem;
        }
    }

`;

export const CharBox = styled.div`

    display: flex;
    flex-direction: column;
    background: #525252;
    position: relative;
    /* width: 100%; */
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem 0;

    .smallLetter{
        font-size: .7rem;
        line-height: 1.2rem;
    }

    .pill{
        border: 1px solid #f5f5f5;
        padding: .2rem;
        border-radius: 1rem;
        cursor: pointer;
        &:hover{
            background: #313131;
        }
    }

    td{
        width: 25%;
    }

    input{
        width: 2rem;
        background: transparent;
        border: 0;
        border-bottom: 1px solid grey;
        color: #f5f5f5;
    }

`;