import { useEffect, useState } from "react"
import { ActionItem, MoreButton } from "./styles";
import { BsFillArrowRightSquareFill } from 'react-icons/bs'
import { AiFillInfoCircle } from 'react-icons/ai'
import { Actions } from "./styles";
import API from "../../services/api";
import { DiceList } from "../DiceList";
 

export function TableTop(){

    const [ url, setUrl ] = useState('')
    const [ showAction, setShowActions ] = useState(false)
    const [ actions, setActions ] = useState()
    const [ character, setCharacter ] = useState()
    const [ isMobile, setIsMobile ] = useState(true)
    const [ campaingId, setCampaingId ] = useState()

    async function getCharacter(){

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let idtt = urlParams.get('idtt')
        let idch = urlParams.get('idch')
        let ridc = urlParams.get('ridc')
        let finalUrl = 'https://www.owlbear.rodeo/game/'+idtt

        setUrl(finalUrl)
        setCampaingId(ridc)

        const {data} = await API.get('get_character.php?id_character='+idch)
        setCharacter(data[0])
        
    }

    function setModifier(value){
        switch(parseInt(value)) {
              case 1:
                return '-5'
                break;
              case 2:
                return '-4'
                break;
              case 3:
                return '-4'
                break;
              case 4:
                return '-3'
                break;
              case 5:
                return '-3'
                break;
              case 6:
                return '-2'
                break;
              case 7:
                return '-2'
                break;
              case 8:
                return '-1'
                break;
              case 9:
                return '-1'
                break;
              case 10:
                return '+0'
                break;
              case 11:
                return '+0'
                break;
              case 12:
                return '+1'
                break;
              case 13:
                return '+1'
                break;
              case 14:
                return '+2'
                break;
              case 15:
                return '+2'
                break;
              case 16:
                return '+3'
                break;
              case 17:
                return '+3'
                break;
              case 18:
                return '+4'
                break;
              case 19:
                return '+4'
                break;
              case 20:
                return '+5'
                break;
              case 21:
                return '+5'
                break;
              case 22:
                return '+6'
                break;
              case 23:
                return '+6'
                break;
              case 24:
                return '+7'
                break;
              case 25:
                return '+7'
                break;
              case 26:
                return '+8'
                break;
              case 27:
                return '+8'
                break;
              case 28:
                return '+9'
                break;
              case 29:
                return '+9'
                break;
              case 30:
                return '+10'
                break;
              default:
                return "-"
            } 
    }

    async function getActions(){
        
        if(character?.actions===undefined){
            return
        }

        const data = character?.actions
        const arrData = data.split("##")
        let newArr = []

        arrData.map((item)=>{
            if(item.length>0){
                newArr.push(item.split("//"))
            }
            
        })


        setActions(newArr)
    }

    async function rollDice(type, label, value, pro=0, dice=20){
        let details = ''
        


        const finalLabel = label.replace(' ','%20')
        let finalValue = 0

        if(type==='puredice'){

            finalValue = Math.floor( Math.random() * parseInt(value) ) +1;

            if(character?.id!=='admin'){
                await API.get('add_roll.php?id_campaing='+campaingId+'&id_character='+character?.id+'&type=d'+value+'&value='+finalValue)
                if(isMobile){
                    window.alert("d"+value+" : "+finalValue)
                }
            }else{
                window.alert("d"+value+" : "+finalValue)
            }
            
            return

        }

        

        if(type==='test'){
            const diceValue = Math.floor( Math.random() * 20 ) +1
             finalValue = parseInt(setModifier(value)) + diceValue;
             if(parseInt(setModifier(value))>=0){
                details = diceValue + " + "+parseInt(setModifier(value))
             }else{
                details = diceValue + " - "+(parseInt(setModifier(value))*-1)
             }
             
            if(pro===1){
                finalValue += Math.round(parseInt(character?.proeficience)/2)
                details += " + "+Math.round(parseInt(character?.proeficience)/2)
            }

            if(pro===2){
                finalValue += parseInt(character?.proeficience)
                details += " + "+parseInt(character?.proeficience)
            }

            if(pro===3){
                finalValue += parseInt(character?.proeficience)*2
                details += " + "+parseInt(character?.proeficience)*2
            }
            
            
        }else if(type==='hit'){
            const diceValue = Math.floor( Math.random() * 20 ) +1
            finalValue = parseInt(value) + diceValue;       
            if(parseInt(value)>=0){
                details = diceValue + " + "+parseInt(value) 
            }else{
                details = diceValue +" - "+(parseInt(value)*-1) 
            }

            
           
       }else if(type==='hitM'){
            finalValue = parseInt(value) + Math.floor( Math.random() * 20 ) +1;           
           
       }else{
            
            if(!value.includes('d')){
                finalValue = parseInt(value)

            }else{

                const numberOfDices = value.split('d')[0]
                const typeOfDice = value.split('d')[1].split('+')[0]
                let mod = value.split('d')[1].split('+')[1]
                if(mod===undefined){
                    mod = parseInt(value.split('d')[1].split('-')[1])*-1
                }

                
                

                if(numberOfDices>1){

                    for(let i=0; i<numberOfDices;i++){
                        const diceValue = Math.floor( Math.random() * parseInt(typeOfDice) ) +1;
                        finalValue =  finalValue + diceValue
                        if(details===''){
                            details += diceValue
                        }else{
                            details += " + "+diceValue
                        }
                    }

                    if(mod!==undefined){
                        finalValue =  finalValue + parseInt(mod)
                        if(parseInt(mod)>=0){
                            details += " + "+parseInt(mod)
                        }else{
                            details += " - "+(parseInt(mod)*-1)
                        }
                        
                    }
                    

                }else{
                    finalValue =  Math.floor( Math.random() * typeOfDice ) +1;
                    details += finalValue
                    if(mod!==undefined){
                        
                        finalValue =  finalValue + parseInt(mod)
                        
                        if(parseInt(mod)>=0){
                            details += " + "+parseInt(mod)
                        }else{
                            details += " - "+parseInt(mod)
                        }
                    }
                }

            }
            
            
        }

        if(type !=='hitM' && type !== 'effectM'){
            await API.get('add_roll.php?id_campaing='+campaingId+'&id_character='+character?.id+'&type='+finalLabel+'&value='+finalValue+'&details='+details.toString())
            if(isMobile){
                window.alert(label+" : "+finalValue+"("+details.toString()+")")
            }
        }else{
            window.alert(label+" : "+finalValue)
        }

        

    }

    useEffect(()=>{

        getCharacter()

    },[])

    useEffect(()=>{
        getActions()
    },[character])



    return(
        <>
        <iframe src={url} 
                is="x-frame-bypass"
                style={{margin:0, padding:0, minHeight:'100vh', width:'100%'}}
                frameborder="0">
        </iframe>
        {
            showAction
            ?<Actions>
                <p
                             style={{fontSize:'.7rem', textAlign:"right"}}
                                onClick={()=>setShowActions(false)}
                            >Fechar</p>
                            <h5>{character?.name}</h5>
                            <DiceList rollDice={rollDice} />
                            <br />
                {
                    actions.map((item)=>{
                        return(
                            <>
                             
                                <ActionItem>
                                    <strong style={{lineHeight:'1.7rem'}}>{item[0]}
                                        {   
                                            /^\*/.test(item[5])
                                            ?null
                                            :<>&nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[5]} /></>
                                        }
                                    </strong>
                                    <p>
                                        {
                                            /^\*/.test(item[1])
                                            ?null
                                            :
                                            <>
                                                HIT <span onClick={()=>rollDice('hit',item[0],item[1])} className="pill">{item[1]}</span> 
                                                &nbsp;
                                            </>
                                            
                                        }

                                        {
                                            (/^\*/.test(item[2]) && /^\*/.test(item[3]))
                                            ?null
                                            :'EF'
                                        }

                                        {   
                                            /^\*/.test(item[2])
                                            ?null
                                            :<span 
                                            onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[2] ,item[2])}
                                            className="pill">{item[2]}</span>
                                        }

                                        {   
                                            /^\*/.test(item[3])
                                            ?null
                                            :<span 
                                            onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[3] ,item[3])}
                                            className="pill">{item[3]}</span>
                                        }

                                        {   
                                            /^\*/.test(item[4])
                                            ?null
                                            :item[4]
                                        }

                                        
                                        </p>
                                        
                                        {/* EF:  {item.length>5?<span className="pill" onClick={()=>rollDice('effect','Efeito '+item[0]+" "+item[3],item[3])}>{item[3]}</span>:null}  */}
                                        {/* &nbsp;{item[item.length-2]}  */}
                                        {/* &nbsp;<AiFillInfoCircle style={{cursor:"pointer"}} title={item[item.length-1]} /></p> */}
                                </ActionItem>
                                {/* <FaEdit 
                                    className="toTheRight" 
                                    style={{color:"#f5f5f5", cursor:"pointer"}} 
                                    onClick={()=>setEditingActions(true)}
                                /> */}
                            </>
                        )
                    })
                }
               
            </Actions>
            :<MoreButton>
                <BsFillArrowRightSquareFill
                onClick={()=>setShowActions(true)}
                />
            </MoreButton>
        }
        </>
        
    )
}